










































































































.caption-editor {
  width: auto;
  &.caption {
    width: 100%;
  }

  &.editing:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,0.6);
    z-index: -1;
    @apply border-b border-aba-blue;
  }

  .caption-text[contenteditable="true"] {
    /*box-shadow: 0 0 0 5px rgba(255,255,255,0.4);*/
    /*border-radius: 1px;*/
    /*z-index: 100;*/

    &:focus {
      outline: none;
      @apply text-aba-blue;
    }
  }
}
