


































































































@import "../../styles/vars";
@import "../../styles/mixins";
:root {
  --logo-current-scale: 1;
  --logo-main-height: 4.5rem;
  @include wider-then( 310px ) {
    --logo-main-height: 5.6rem;
  }
  @include wider-then-phone {
    --logo-main-height: 8rem;
  }
}
#app {
  .main-header{
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    padding: 0;
    z-index: $z-index-header;
    pointer-events: none;

    .logo-wrap {
      align-self: stretch;
      display: flex;
      align-items: center;
      height: calc(var(--logo-main-height) * var(--logo-current-scale));
      pointer-events: all;
      transition: background 0.2s, height 0.2s;
      a {
        text-decoration: none;
      }
      .logo-link {
        display: flex;
        height: calc(var(--logo-main-height) * var(--logo-current-scale) * 0.75);
        justify-content: center;
        background: radial-gradient(closest-side, #4b4b4b, $color-bg-semitransparent);
        border: solid $color-aba-blue;
        border-width: 0 1px;
        padding: 0 0.5rem;
        color: $color-aba-blue;

        transition: background 0.2s, height 0.2s;

        &:hover {
          background: radial-gradient(closest-side, #b9b9b9, $color-bg-semitransparent);
        }

        & > span {
          font-size: calc(var(--logo-main-height) * var(--logo-current-scale) * 0.75 * 0.95);
          height: calc(var(--logo-main-height) * var(--logo-current-scale) * 0.75);
          width: calc(var(--logo-main-height) * var(--logo-current-scale) * 0.75 * #{$logo-ratio});
          transition: font-size 0.2s, height 0.2s, width 0.2s;
        }
      }
      &.small {
        height: $base-size;
        .logo-link {
          height: calc(#{$base-size} * 0.75);
          background: radial-gradient(closest-side, #fff, $color-bg-semitransparent);
          & > span {
            font-size: 2rem;
            height: 100%;
            width: calc(#{$base-size} * 0.75 * #{$logo-ratio});
            transition: font-size 0.2s, height 0.2s, width 0.2s;
          }
        }
      }
    }
    nav {
      display: flex;
      align-items: center;
      width: 100%;
      pointer-events: all;

      a {
        &.nav-item {
          display: flex;
          align-items: center;
          height: $base-size * 0.75;
          background: $color-bg-semitransparent;
          padding: $base-padding /2 $base-padding;
        }
      }
      .nav-item.secondary-item {
        display: none;
        @include wider-then-phone {
          display: flex;
        }
      }
    }

    .login {
      flex-shrink: 0;
      margin-left: auto;
      pointer-events: all;
    }

  }

  .user-actions {
    padding: $base-padding / 2;
  }
}
