














































































































@import '../../../styles/vars';
.login-wrap { position: relative; }
.v-card.login-container {
  max-width: 300px;
  width: 100%;
  border: none;

  .v-card__title {
    padding-top: 0;
  }
  .message {
    height: 0;
    color: red;
    overflow: hidden;
    margin: 0;
    transition: height 0.2s 0.5s;
    font-size: 90%;

    &.open {
      // height: $base-size;
    }
  }
}
.forgot-form {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  // transition: left $transition-time;

  // &.open {
  //   left: 0;
  // }
}

.push-enter-active, .push-leave-active {
  transition: left $transition-time;
}
.push-enter, .push-leave-to {
  left: 100%;
}
