@import "vars";
@import "tailwindcss/base";
//noinspection CssInvalidAtRule
html * {
  box-sizing: border-box;
  h1 {
    line-height: 1em;
    @apply text-2xl;
  }
  h2 {
    line-height: 1em;
    @apply text-xl;
  }
}

input, textarea, button, a {
  &:focus {
    outline: none;
  }
}

a {
  color: $color-aba-blue;
  &:focus, &:hover {
    text-decoration: underline;
  }
}

p {
  margin-bottom: 1em;
}

//noinspection CssInvalidAtRule
.desc {
  @apply text-sm text-gray-700;
}

@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "autofill";
@import "buttons";
