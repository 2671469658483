






































































































































































































































































































.text-editor {
  .ProseMirror {
    &:focus {
      outline: none;
    }
  }

  .menu-bar {
    position: sticky;
    top: 0;
    z-index: 100;
    /*width: max-content;*/
    @apply bg-milk;
  }

  .menu {
    @apply py-xs pt-base pr-base bg-milk;
    button {
      @apply w-1/2base h-1/2base border-r border-gray-400 rounded-none bg-transparent;
      &:last-child {
        border: none;
      }

      i {
        @apply text-xl text-gray-900;
      }

      &.active {
        i {
          @apply text-xl text-aba-blue;
        }
      }
    }
  }
}

.menu-bubble {
  --popper-bg-color: #3e4b78;
  --popper-border-color: #3e4b78;
  --popper-border-width: 1px;

  .popper {
    padding: 0;
    color: #fff;
  }

}

$color-black: #ccc;
$color-white: #fff;

.menububble {
  @apply absolute flex items-center shadow-soft min-w-0 bg-white border-2 border-gray-300 rounded-sm;
  transform: translateX(-50%);
  z-index: 20;
  margin-bottom: 0.5rem;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;

  &.is-active {
    opacity: 1;
    visibility: visible;
  }

  &__form {
    display: flex;
    align-items: center;
  }

  &__input {
    font: inherit;
    border: none;
    background: transparent;
    color: $color-white;
  }
}

.editor *.is-empty:nth-child(1)::before,
.editor *.is-empty:nth-child(2)::before {
  content: attr(data-empty-text);
  @apply text-gray-400 font-thin italic capitalize;
  float: left;
  pointer-events: none;
  height: 0;
}
