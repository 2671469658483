










































































































































































































































































































































































































































































































































@import "../../../styles/vars";

:root {
  --popper-bg-color: blue;
  --popper-border-color: blue;
  --popper-text-color: #fff;
  --popper-border-width: 0px;
  --popper-arrow-size: 12px;
  --popper-drop-shadow: drop-shadow(0 2px 10px rgba(0,0,0,0.05));
}

$pop-border-w: var(--popper-border-width);
$pop-bg-color: var(--popper-bg-color);
$pop-border-color: var(--popper-border-color);
$arrow-size: calc(var(--popper-arrow-size) - var(--popper-border-width) * 2);
$arrow-after-size: var(--popper-arrow-size);

@mixin arrow ($side) {
  $opposite-side: '';
  $scale: 0.6;

  $transform: scaleX($scale);
  $b-color: $pop-bg-color transparent;
  $b-after-color: $pop-border-color transparent;

  @if ($side == 'left' or $side == 'right') {
    $transform: scaleY($scale);
    $b-color: transparent $pop-bg-color;
    $b-after-color: transparent $pop-border-color;
  }

  @if ($side == 'top') {
    $opposite-side: 'bottom';
  }
  @else if ($side == 'bottom') {
    $opposite-side: 'top';
  }
  @else if ($side == 'left') {
    $opposite-side: 'right';
  }
  @else if ($side == 'right') {
    $opposite-side: 'left';
  }
  .popper__arrow {
    #{$opposite-side}: calc(var(--popper-arrow-size) * -1);
    &:before, &:after {
      transform: $transform;
      border-color: $b-color;
      border-#{$opposite-side}-width: 0;
    }
    &:after {
      border-color: $b-after-color;
      #{$side}: 0;
    }
  }
}

.dark {
  .popper {
    --popper-bg-color: #{$color-dimmed};
    --popper-border-color: #fff;
    --popper-text-color: #fff;
    --popper-drop-shadow: drop-shadow(0 2px 60px #{darken($color-dimmed, 8%)});
  }
}

.popper {
  max-width: calc(100vw - #{$base-padding * 2});
  /*max-height: 100%;*/
  @apply p-base;

  width: max-content;
  background-color: var(--popper-bg-color);
  position: absolute;
  font-size: $font-size-caption;
  color: var(--popper-text-color);
  filter: var(--popper-drop-shadow);
  z-index: 200000;
  // font-family: $font-family-sans-serif;

  .popper-content{
    overflow-y: auto;
    height: 100%;
    width: 100%;
    //word-break: break-all;
    text-align: start;
    color: var(--popper-text-color);

    a:hover {
      color: var(--popper-text-color);
      text-decoration: underline;
    }
  }
  .popper__arrow {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--popper-arrow-size);
    height: var(--popper-arrow-size);
    z-index: 0;

    &:before, &:after {
      content: '';
      box-sizing: border-box;
      position: absolute;
      width: 100%;
      height: 100%;
      border-width: $arrow-size;
      border-style: solid;
    }
    &:after {
      z-index: -1;
      border-width: $arrow-after-size;
    }
  }

  &[data-popper-placement^="top"] {
    @include arrow(top);
  }

  &[data-popper-placement^="bottom"] {
    @include arrow(bottom);
  }

  &[data-popper-placement^="right"] {
    @include arrow(right);
  }

  &[data-popper-placement^="left"] {
    @include arrow(left);
  }
}

.popper-trigger {
  position: relative;
}

.popper-hover-enter-active {
  transition: opacity 0.2s
}
.popper-hover-leave-active {
  transition: opacity 0.5s
}
.popper-hover-enter, .popper-hover-leave-to {
  opacity: 0;
}

.popper-click-enter-active, .popper-click-leave-active {
  transition: opacity 0.2s
}
.popper-click-enter, .popper-click-leave-to {
  opacity: 0;
}

.popper-instant-enter-active, .popper-instant-leave-active {
  opacity: 1;
  transition: none;
}
.popper-instant-enter, .popper-instant-leave-to {
  opacity: 0;
}
