





















































































































































































































































.suggestions {
  position: relative;
  display: block;
  .popper-trigger {
    @apply block h-full;
  }
  .popper {
    padding: 0;
    border: #ccc solid;
    border-width: 0 1px 1px 1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0 -1px 0 blue;

    .popper-content {
      max-height: 20rem;
      @apply block overflow-auto w-full h-full;
    }

    li {
      @apply px-base py-sm text-sm whitespace-no-wrap;
      &.highlighted {
        @apply text-aba-blue bg-gray-100;
      }
    }
  }
}
