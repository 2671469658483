













































































































































// @import "../styles/vars";
// @import "../styles/mixins";
span.popper-trigger {
    display: block;
    height: 100%;
}
