


















































@import "../../styles/vars";
.main-footer {
  position: fixed;
  bottom: 2vh;
  left: 2vh;
  height: $base-size;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: transparentize($color-bg, 0.2);
  h3 {
    position: relative;
    .top-right {
      opacity: 0;
      position: absolute;
      bottom: calc(100% - #{$small-padding});
      left: calc(100% - #{$small-padding});
      transition: opacity 0.2s;
    }
    &:hover .top-right {
      opacity: 1;
    }
  }
}
