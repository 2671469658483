





















































































































































































































































.attachment-editor-cell {
  position: relative;
  overflow: hidden;
  &.framed {
    border-color: #e7e7e7 !important;
  }
  .video-player {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  .dimensions-editor.focused {
    padding-right: 0 !important;
  }
}
