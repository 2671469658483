














































































.calendar-cell {
  transition: font-size 0.05s, background-color 0.1s;
  &:active {
    @apply rounded-none text-aba-blue text-xl;
  }
  &:not(.--disabled):not(.--selected):hover {
    /*@apply text-base;*/
  }
  &.--disabled:not(.--between) {
    opacity: .1;
  }
  &.--between {
    @apply bg-aba-blue-semi rounded-none;
  }
  &.--weekend {
    @apply text-aba-blue;
  }
  &.--selected {
    @apply text-white relative text-xl bg-aba-blue;
  }
  &.--is-other-month-day:not(.--disabled):not(.--between):not(.--selected) {
    @apply text-shadow;
  }
  &:focus:not(.--selected) {
  }
  &.--between:focus {
  }
}
