

























































































































































































































































































































































































































































.user-editor {
  background: #fff;
  .row > div {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.v-select__selection, .v-list-item__title {
  text-transform: capitalize
}
