



















































































































































































@import "../../styles/vars";
@import "../../styles/mixins";
.post-cell {
  position: relative;
  /*border-bottom: 1px solid #000;*/
  hyphens: auto;

  .title-letter {
    font-size: var(--title-letter-font-size);
  }

  &.upcoming {
    border-bottom: none;
    & > * {
      color: $color-aba-blue
    }
    &:after {
      content: none;
    }
    .excerpt {
      margin-bottom: $small-padding !important;
    }
  }

  &.internal {
    // border-bottom: 1px solid $color-aba-blue;
  }
  &.draft {
    &:after {
      border-style: dashed;
      border-color: $color-aba-red;
    }
    & img {opacity: 0.5;}
    & * {
      font-style: italic !important;
    }

    a {
      @apply text-gray-500;
      &:hover {
        @apply text-aba-red no-underline;
      }
    }
    .status {
      background: $color-aba-red;
    }
  }

  a {
    @apply text-black;
    &:hover {
      @apply text-aba-blue no-underline;
    }
  }

  //.popper, .popper > span {
  //  display: block;
  //}

  a.popper-content {
    width: max-content;
  }

  .type-badge {
    text-transform: capitalize;
  }
  .badge {
    @apply px-sm py-xs text-xs capitalize truncate bg-gray-200;
    border-radius: 1px;
    min-width: 0;
  }

  .upcoming-label {
    position: relative;
    z-index: 10;
    padding: 0.1rem 0.4rem;
    background: $color-aba-blue;
    color: #fff;
    font-size: 75%;
    text-transform: uppercase;
    left: -6px;
    top: -4px;
    transform: rotate(3deg);
  }

  .status {
    @apply text-xs;
    position: absolute;
    white-space: nowrap;
    text-transform: capitalize;
    padding: 0.18rem 0.45rem;
    bottom: calc(100% - 7px);
    left: -5px;
    font-size: 87%;
    background: $color-aba-blue;
    color: white;
    font-weight: lighter;
    border-radius: 1rem;
    z-index: 100;
    transform: rotate(-1.5deg);
  }

  a.edit-button {
    text-decoration: none !important;
  }
  //.edit-button:not(.active) {
  //  opacity: 0;
  //  transform: opacity $transition-time;
  //}
  //.edit-button.active {
  //  background: transparentize($color-dimmed, 0.8);
  //  border-radius: 50%;
  //  color: $color-aba-blue;
  //}

  .excerpt {
    @include multi-line-truncate();
    &.short {
      @include multi-line-truncate(2);
    }
  }

  //&:hover {
  //  .edit-button {
  //    opacity: 1;
  //  }
  //}

  &.horizontal {
    @include wider-then(472px) {
      grid-column: auto / span 2;
      h1 {
        font-size: $h2;
      }
    }
  }

  &.vertical {
    grid-row: span 2;
  }

  &.big {
    grid-row: span 2;
    @include wider-then(472px) {
      grid-column: span 2;
      h1 {
        font-size: $h1;
      }
    }
  }

}

.thumbnail-box {
  position: relative;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    filter: saturate(0);

    opacity: 0;
    transition: opacity 0.5s;
    &[lazy=loaded] {
      opacity: 1;
    }
  }
}
