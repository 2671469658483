





































































































.dropzone{
  position: relative;
  .dropzone-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    z-index: 10000;
    background: rgb(199, 199, 199, 0.74);
    border: 5px dashed rgb(170, 179, 182, 1);
    color: #fff;

    &.hover {
      background: rgba(192, 192, 192, 0.98);
    }
  }
}
