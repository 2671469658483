


















































































































































































































































































































































































































































































































.img-n-o-wrap {
  /*display: inline-block;*/
  position: relative;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
  .__img-n-o {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;

    & > * {
      position: relative;
    }
  }
}
