





















































































































@import '../../styles/vars';
@import '../../styles/mixins';
$panel-bg-color: mix(rgba(70, 82, 130, 0.95), $color-bg, 30%);
.user-menu {
  margin-left: auto;
  * {
   box-sizing: border-box;
  }
  .user-menu-button {
    width: $base-size;
    height: $base-size;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: $base-padding / 2;

    i {
      transition: transform 0.15s;
      &.open {
        color: white;
        &:not(.user) {
          transform: rotate(90deg);
        }
      }
    }
  }
  .sliding-panel {
    width: 100%;

    //border: $border-thick-w solid $color-aba-blue;
    @include wider-then-phone {
      width: auto;
      margin-right: $base-padding / 2;
    }
  }
  .user-actions-wrapper {
    padding-top: calc(#{$base-size} - #{$border-thick-w});
    background: $panel-bg-color;
    font-weight: 300;
    overflow: hidden;

    ul.user-actions {
      @apply px-sm;
      &:not(:last-child) {
        border-bottom: 1px lighten($panel-bg-color, 10%) solid;
      }
      li {
        display: flex;
        align-items: center;
        min-height: $base-size;
        padding: $base-padding / 2 $base-padding;
        color: #fff;
        background: transparent;
        min-width: 175px;
        &:not(:last-child) {
          border-bottom: 1px lighten($panel-bg-color, 5%) solid;
        }
        & a {
          color: #fff;
          cursor: pointer;
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      button {
        width: auto;
      }
      a {
        margin: 0;
      }
    }
  }
}
