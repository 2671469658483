










































































































































































































@import "../../styles/vars";
@import "../../styles/mixins";

.post-feed-grid {
  --title-letter-font-size: 3.75rem;

  width: calc(100vw - #{$base-size * 2});
  max-width: 800px;
  box-sizing: content-box;
  min-height: calc(100vh - #{$base-size} * 2);
  position: relative;
  margin: $base-size * 2 auto 0;

  .post-cell-wrap {
    position: absolute;
    transition: left 0.2s, top 0.2s;
    .post-cell {
      position: relative;
    }
  }

  .back-button {
    .material-icons{
      font-size: var(--title-letter-font-size);
    }
  }

  canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}
