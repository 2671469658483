//noinspection CssInvalidAtRule
button, .button {
  @apply relative h-3/4base flex items-center justify-center px-base;
  cursor: pointer;

  &:focus {
    @apply text-aba-blue;
  }
  &:hover {
    @apply text-aba-blue;
  }
  &:active {
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 80%;
      height: 80%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: rgba(0,0,0,0.1) !important;
      @apply rounded-3/8base;
    }
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.compact {
    @apply w-3/4base px-0;
  }

  &.large {
    @apply h-base w-base;
    &:active {
      @apply rounded-1/2base;
    }
  }
  &.xl {
    @apply h-x2 w-x2;
    @apply rounded-1/2base;
    &:active {
    }
  }

  &.rounded {
    @apply rounded-3/8base;
    &.large {
      @apply rounded-1/2base;
    }
  }
}
