






































































































































@import "../../styles/vars";
// .editor {
//   //font-family: $font-family-sans-serif;
// }
.type-with-title {
  @apply text-gray-600 font-thin;
  &:after {
    content: ': '
  }
}
