


































































































@import "../../../styles/vars";

.sliding-panel-wrap {
  .trigger {
    position: relative;
    cursor: pointer;
    z-index: $z-high + 1;
  }

  .sliding-panel {
    $transition-time: 0.15s;
    $reverse-time: $transition-time;
    $reverse-delay: $transition-time * 0.7;

    position: fixed;
    padding: 0;

    box-sizing: border-box;
    max-height: 100%;
    overflow: auto;
    opacity: 0;

    z-index: $z-high;

    top: -1000px;
    transition: top $reverse-time $reverse-delay ease-in-out, opacity 0s $reverse-delay * 2;

    &.open {
      top: 0;
      opacity: 1;
      transition: top $transition-time ease-in-out, opacity 0s 0s;
    }

    &.align-end {
      right: 0;
    }
    &.align-start {
      left: 0;
    }

    &.from-right {
      top: unset;
      right: -100%;
      transition: right $reverse-time $reverse-delay ease-in-out;

      &.align-end {
      }

      &.open {
        top: unset;
        right: 0;
        transition: right $transition-time ease-in-out;
      }
    }
  }
}
