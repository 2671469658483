












































































































































.dropdown-select.input {
  position: relative;
  display: inline-flex;
  width: auto;
  align-items: center;
  background: transparent;
  padding: 0;

  user-select: none;
  transition: background 0.2s;
  white-space: nowrap;

  @apply border-aba-blue border-b mb-base;

  &.open {
    z-index: 10;
  }

  &.disabled {
    pointer-events: none;
    border-bottom: none;
  }

  .options {
    position: absolute;
    top: 100%;
    left: 0;
    list-style: none;

    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    padding: 0;
    background: #fff;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
    z-index: 20;
    @apply border-aba-blue border-b;
    overflow: hidden;
    transition: height 0.2s;
  }
  .option {
    @apply py-xs;
  }
  .value, .option {
    @apply px-sm h-2/3base;
    box-sizing: content-box;
    display: flex;
    align-items: center;

    &:active {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .value.no-value {
    @apply text-gray-500 italic font-thin;
  }

  .option:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  button {
    flex-shrink: 0;
    font-size: 80%;
  }

}
