
























































































// @import "../../../assets/styles/vars";
// @import "../../../assets/styles/mixins";
$basic-size: 3.8rem;
$basic-padding: 1rem;
$content-min-width: 5rem;
$tag-inline-height: 3.8rem;

.popover-modal {
  position: fixed;
  z-index: 200100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .modal-shadow {
    content: '';
    position: fixed;
    height: calc(100vh + 10px);
    width: calc(100vw + 10px);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }

  .modal-content-box {
    position: relative;
    width: 100%;
    max-width: min-content;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s;
}

/* .fade-leave-active below version 2.1.8 */
.fade-enter, .fade-leave-to {
  opacity: 0;
}
