




























































@import "https://fonts.googleapis.com/icon?family=Material+Icons";
// @import "https://fonts.googleapis.com/css2?family=Barlow+Condensed&display=swap";
@import "https://webfonts2.radimpesko.com/RP-W-55487c3b934c2b2932000005.css";
@import "./styles/main.scss";
@import "./styles/vars";

:root {
  // --font-main: 'Barlow Condensed', sans-serif;
  --win-height: 100%;
  --font-main: 'F Grotesk Book', sans-serif;
  --bg-color: #{$color-bg};
  --bgocolor-semi: #{transparentize($color-bg, 0.05)};
}
body {
  background: $color-bg;
  font-family: var(--font-main);
  width: 100vw;
  height: 100vh;
  height: var(--win-height);
}
html {
}

#app {
  width: 100vw;
  height: 100%;
}

.post-feed {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100% - #{$base-size * 2});
}
