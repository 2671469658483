




















































.location {
  .desc {
    padding: 0 !important;
    .map {
      @apply mt-sm;

      .gm-style {
        & > .gmnoprint,
        & > .gmnoscreen,
        & > [draggable='false'],
        & > div > a {
          display: none !important;
        }
      }
    }
  }
}
