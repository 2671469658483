























































































































.dimensions-editor{
  cursor: text;
  transition: width 0.2s, background 0.2s;

  .input {
    padding: 5px;
    background: transparent;
    min-width: 0 !important;
    &:focus {
      color: var(--color-input-foxus, #0000ff);
    }
  }
}
