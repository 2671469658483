








































































































































$cell-width: 40px;
$cell-height: 40px;
$light-gray: #f7f7f9;

.calendar {
  &__header {
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
  }

  &__weeks {
    display: flex;
    justify-content: flex-start;
  }

  &__week-day {
    display: inline-block;
    width: $cell-width;
    height: 40px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    line-height: 40px;
  }

  &__body {
    max-width: calc(#{$cell-width} * 7);
    min-width: calc(#{$cell-width} * 7);
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
  }

  &__month-btn {
    background-color: $light-gray;
    color: #383838;
    border: none;
    border-radius: 3px;
    appearance: none;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    /*background-image: url('../assets/arrow-point-to-right.svg');*/
    background-size: 12px;
    background-position: center;
    background-repeat: no-repeat;
    width: 50px;
    height: 30px;

    &:first-child {
      transform: rotate(-180deg);
    }

    &:focus {
      outline: none;
      background-color: darken($light-gray, 10%);
    }
  }

}

