



































































































































































































































































































































@import "../../../styles/vars";
@import "../../../styles/mixins";
$h: 200px;
.attachments-grid {
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
  // grid-gap: 5px;
  // grid-auto-rows: 256px;
  display: flex;
  flex-flow: wrap;
  margin: 0 -2px;

  .cell-wrap {
    width: 100%;
    max-width: 286px;
    margin: 2px;
    .attachment-editor-cell {
      width: 100%;
      height: 0;
      padding-top: 56.25%;
      position: relative;
      & > * {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }
  .preview-wrap {
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.new-attachment-cell {
  width: fit-content;
}

.drag-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
  background: rgba(170, 179, 182, 0.5);
  border: 5px dashed rgba(170, 179, 182, 1);
  color: #fff;

  &.hover {
    background: rgba(179, 205, 214, 0.98);
  }
}

i.remove {
  $w: 12px;
  $c: rgb(94, 94, 94);
  display: inline-block;
  position: absolute;
  box-sizing: border-box;
  width: $w;
  height: $w;
  padding: 0;

  &::before, &::after {
    $w: $w * 1;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: $w;
    height: $w;
    border-right: 1px solid $c;
    box-sizing: border-box;
  }
  &::before {
    transform: translate(-50%, -50%) rotateZ(45deg) translateX(-$w/2 + 0.5px);
  }
  &::after {
    transform: translate(-50%, -50%) rotateZ(-45deg) translateX(-$w/2+0.5px);
  }
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s !important;
}

.ghost {
  opacity: 0.5;
}
